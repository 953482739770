import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import './MainComponent.scss';
import Header from './Header/Header';
import Intro from './Intro/Intro';
import Special from './Special/Special';
import Plan from './Plans/Plans';
import RowButtons from './RowButtons/RowButtons';
import Footer from './Footer/Footer.js';
import Burgertoggle from './BurgerToggle/BurgerToggle';

class MainComponent extends Component {
    render() {
      return (
        <div className="main">
          <Burgertoggle/>
          <Header />
          <Intro />
          <Special/>
          <Plan/>
          <RowButtons/>
          <Footer />
        </div>
      );
    }
  }
  
  export default MainComponent;
  