import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Buttons.scss';

class Buy extends Component {
  render() {
    return (
        <div className="button-container">
            <AnchorLink href="#footer-contact">
                <div className="btn-shape buy-btn">
                    <div className="top-text">Contact Now</div>
                    <div className="bottom-text">for business</div>
                </div>
            </AnchorLink>
        </div>
    );
  }
}

export default Buy;