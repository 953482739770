import { slide as Menu } from 'react-burger-menu';
import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './BurgerToggle.scss';

class BurgerToggle extends Component {
  showSettings (event) {
    event.preventDefault();
    
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu className="burger-wrapper">
          <AnchorLink className="nav-item" href='#home'>
            Home
            </AnchorLink>
            <a className="nav-item" href="/blog/guides.html">
            Guides
            </a>
            <AnchorLink className="nav-item" href='#plan'>
            Pricing
            </AnchorLink>
            <AnchorLink className="nav-item" href="#/">
            Download
            </AnchorLink>
            <AnchorLink className="nav-item" href='#footer-contact'>
            Contact Us
            </AnchorLink>
      </Menu>
    );
  }
}

export default BurgerToggle;