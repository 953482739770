import React, { Component } from 'react';
import YAML from 'yaml';
import axios from 'axios';
import './Buttons.scss';

class Download extends Component {
  constructor(props){
    super(props);
    this.state = {
      version: '-.-.-',
      fileName: ''
    }
    this.getLatestVersionData = this.getLatestVersionData.bind(this);
  }
  componentDidMount() {
    this.getLatestVersionData();
  }

  getLatestVersionData() {
    axios.get('https://remotrix-publish-dev.s3-us-west-2.amazonaws.com/latest.yml',
      {
        method: 'GET',
        responseType: 'blob',
        headers: {'Access-Control-Allow-Origin': true},
      }
    )
    .then(res => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result;
        const data = YAML.parse(text);
        const { path, version } = data;
        this.setState({
          fileName: path,
          version
        });
      });
      reader.readAsText(res.data);
    })
    .catch(err => {
      console.log('Error', err);
    });
  }
  render() {
    const { version, fileName } = this.state;
    return (
        <div className="button-container">
            <a id="main-download-link" href={`https://remotrix-publish-dev.s3-us-west-2.amazonaws.com/${fileName}`}>
                <div className="btn-shape download-btn">
                    <div className="top-text">Free Download</div>
                    <div className="bottom-text">{`v${version}-(Windows)`}</div>
                </div>
            </a>
        </div>
    );
  }
}

export default Download;
