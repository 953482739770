import React, { Component } from 'react';
import './Intro.scss';
import Download from '../Buttons/Download';
import Buy from '../Buttons/Buy';

class Intro extends Component {
  render() {
    return (
      <div className="intro-wrapper set-padding" id='home'>
      <div className="row intro-content">
        <div className="col-md-6 left">
            <p className="heading">Remote Desktop Control</p>
            <p className="intro-description">State of the art remote desktop control software for Windows,
            <span style={{ color: '#a9abaa' }}> Mac and Linux</span>. Designed to be deployed in private networks without internet.</p>
            <div className="button-container">
                <div className="button-left">
                  <Download/>
                </div>
                <div className="button-right">
                  <Buy/>
                </div>
            </div>
        </div>
        <div className="col-md-6 right">
            <img src="images/intro_image.png" alt="intro" />
        </div>
      </div>
        <img className="bg background1" src="images/background1.svg" alt="background1" />
        <img className="bg background2" src="images/background2.svg" alt="background2" />
        <img className="bg background3" src="images/background3.svg" alt="background3" />
      </div>
    );
  }
}

export default Intro;