import React, { Component } from 'react';
import Constants from '../../Constants/Constants';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Plans.scss';

class Plans extends Component {
  render() {
    return (
        <div className="plan-wrapper" id="plan">
            <div className="image-container">
                <img src="images/pricing_image.png" alt="pricing_image"/>
            </div>
            <div className="plan-container">
                <div className="heading-main">
                    Choose the right plan
                </div>
                <div className="cards-container">
                    
                    <div className="card-in-plan card-left">
                        <div className="top">
                            <div className="heading">{Constants.plan.basic.heading}</div>
                            <div className="price"><div>$</div><div className="number">{Constants.plan.basic.price}</div><div>/month</div></div>
                        </div>
                        <div className="center">
                            <div>{Constants.plan.basic.feature1}</div>
                            <div>{Constants.plan.basic.feature2}</div>
                            <div>{Constants.plan.basic.feature3}</div>
                            <div>{Constants.plan.basic.feature4}</div>
                            <div>{Constants.plan.basic.feature5}</div>
                        </div>
                        <div className="bottom">
                            <AnchorLink href="#home">
                                <div className="button">
                                    Download
                                </div>
                            </AnchorLink>
                        </div>
                    </div>

                    <div className="card-in-plan card-middle">
                        <div className="top">
                            <div className="heading">{Constants.plan.business.heading}</div>
                            <div className="price"><div>$</div><div className="number">{Constants.plan.business.price}</div><div>/month</div></div>
                        </div>
                        <div className="center">
                            <div>{Constants.plan.business.feature1}</div>
                            <div>{Constants.plan.business.feature2}</div>
                            <div>{Constants.plan.business.feature3}</div>
                            <div>{Constants.plan.business.feature4}</div>
                            <div>{Constants.plan.business.feature5}</div>
                            <div>{Constants.plan.business.feature6}</div>
                            {/* <div className="plus">+ Basic plan</div> */}
                        </div>
                        <div className="bottom">
                            <AnchorLink href="#footer-contact">
                                <div className="button">
                                    Contact Now
                                </div>
                            </AnchorLink>
                        </div>
                    </div>

                    <div className="card-in-plan card-right">
                        <div className="top">
                            <div className="heading">{Constants.plan.enterprise.heading}</div>
                            <div className="price"><div>$</div><div className="number">{Constants.plan.enterprise.price}</div><div>(one time)</div></div>
                        </div>
                        <div className="center">
                            <div>{Constants.plan.enterprise.feature1}</div>
                            <div>{Constants.plan.enterprise.feature2}</div>
                            <div>{Constants.plan.enterprise.feature3}</div>
                            <div className="plus">+ Business plan</div>
                        </div>
                        <div className="bottom">
                            <AnchorLink href="#footer-contact">
                                <div className="button">
                                Contact Now
                                </div>
                            </AnchorLink>
                        </div>
                    </div>

                    
                </div>
            </div>
            <img className="background one" src="images/pricing_background1.svg" alt="bg1"/>
            <img className="background two" src="images/pricing_background2.svg" alt="bg2"/>
        </div>
    );
  }
}

export default Plans;
