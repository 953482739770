import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Header.scss';

class Header extends Component {
  render() {
    return (
      <div className="header-wrapper set-padding">
        <div className="nav-menu">
            <AnchorLink className="nav-item" href='#home'>
            Home
            </AnchorLink>
            <a className="nav-item" href="/blog/guides.html">
            Guide
            </a>
            <AnchorLink className="nav-item" href='#plan'>
            Pricing
            </AnchorLink>
            <AnchorLink className="nav-item" href="#/">
            Download
            </AnchorLink>
            <AnchorLink className="nav-item" href='#footer-contact'>
            Contact Us
            </AnchorLink>
        </div>  
        <div className="logo-container">
          <img
            src="images/remotrix_logo.png"
            alt="reomotrix"
            role="presentation"
            className="remotrix-logo"
          />
          <div className="logo-name">Remotrix</div>
        </div>
      </div>
    );
  }
}

export default Header;
