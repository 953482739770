import React, { Component } from "react";
import "./Special.scss";
import Card from "./SpecialCard";
import Constants from "../../Constants/Constants";

class Special extends Component {
  render() {
    const upcomingFeature = {
      variant: 'info',
      badgeTitle: 'Upcoming Feature',
    };
    const newFeature = {
      variant: 'success',
      badgeTitle: 'New Feature',
    };
    const premiumFeature = {
      variant: 'danger',
      badgeTitle: 'Premium Feature',
    };
    return (
      <div className="Special-wrapper set-padding">
        <div className="heading">What makes Remotrix special</div>
        <div className="row">
          <Card
            url="images/session.png"
            heading={Constants.special.session.heading}
            description={Constants.special.session.description}
            featureBadge={upcomingFeature}
          />
          <Card
            url="images/sharing.png"
            heading={Constants.special.sharing.heading}
            description={Constants.special.sharing.description}
            featureBadge={upcomingFeature}
          />
          <Card
            url="images/chat.png"
            heading={Constants.special.chat.heading}
            description={Constants.special.chat.description}
            featureBadge={newFeature}
          />
        </div>
        <div className="row">
          <Card
            url="images/audio.png"
            heading={Constants.special.audio.heading}
            description={Constants.special.audio.description}
            featureBadge={newFeature}
          />
          <Card
            url="images/intranet.png"
            heading={Constants.special.intranet.heading}
            description={Constants.special.intranet.description}
            featureBadge={upcomingFeature}
          />
          <Card
            url="images/branding.png"
            heading={Constants.special.branding.heading}
            description={Constants.special.branding.description}
            featureBadge={premiumFeature}
          />
        </div>
      </div>
    );
  }
}

export default Special;
