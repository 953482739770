import React from 'react';
import './Special.scss';

const SpecialCard = (props) =>{
  const { featureBadge ,featureBadge: { variant, badgeTitle } = {}} = props || {};
  return (
    <div className="Special-card-wrapper col-md-3">
      <div className="card-content-wrapper">
      <div className="icon">
        <img src={props.url} alt="Special Card" />
      </div>
      <div className="text">
        {
          featureBadge && <h5><span className={`badge badge-${variant || 'primary'} badge-pill`}>{ badgeTitle || '' }</span></h5>
        }
        <div className="heading">{props.heading}</div>
        <div className="description">{props.description}</div>
      </div>
      </div>
    </div>
  )
}

export default SpecialCard;
