import React, { Component } from 'react';
import './RowButtons.scss';
import Download from '../Buttons/Download';
import Buy from '../Buttons/Buy';
class RowButton extends Component {
  render() {
    return (
        <div className="row-button">
            <div className="left-btn">
                <Download/>
            </div>
            <div className="right-btn">
                <Buy/>
            </div>
        </div>
    );
  }
}

export default RowButton;
