import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import {Facebook, LinkedIn} from '@material-ui/icons';

const Section = styled.div`
 
  padding-top: 2vw;
  padding-bottom: 2vw;
  background-color:#212120;
  

  .left{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 70px;
    }
    .social{
      i{
        color: white;
        font-size:20px;
      }

      a{
        color:white;
        &:hover{
          color:#9df1ff;
        }
      }
    }
  }
  .center{
    display: flex;
    justify-content: center;
    align-items: center;
    .link-set{
      width: auto;
      a{
        padding: 4px 15px;
        color: #ffffff;
        font-family: 'open sans';
        font-size: 1.3em;
        text-decoration:none;

        &:hover{
          color:#9df1ff;
        }
      }
    }
  }
  .right{
    text-align: center;
    color: #ffffff;
    .title{
      margin-bottom: 1.5vw;
      
      font-size: 1.5em;
      font-family: 'open sans';
    }
    .email{
      margin-bottom: 0.3vw;
      font-size: 1.3em;
      font-family: 'open sans';
    }
    .phone{
      font-size: 1.3em;
      font-weight: 2em;
      font-size: "open sans";
    }
  }

  @media screen and (max-width: 425px){
    padding-bottom:5px;
    padding-top:10px;
    .left{
      img{
        width: 45px;
      }
      .social{
        margin-top: 1vh;
        img{
          width: 25px;
         
        }
      }
    }
    .center{
      .link-set{
        a{
          padding: 4px 10px;
          font-size: 0.7em;
        }
      }
    }
    .right{
      .title{
        margin-top:6px;
        margin-bottom: 3px;
        font-size: 0.8em;
      }
      .email{
        margin-bottom: 0px;
        font-size: 0.7em;
      }
      .phone{
        font-weight: 0;
        font-size: 0.7em;
        padding-bottom:5px;
      }
    }
  }

  @media screen and (max-width: 768px)and (min-width: 426px){
    .left{
      img{
        width: 45px;
      }
      .social{
        margin-top: 1vh;
        img{
          width: 25px;
         
        }
      }
    }
    .center{
      .link-set{
        a{
          padding: 4px 10px;
          font-size: 0.7em;
        }
      }
    }
    .right{
      .title{
        margin-bottom: 5px;
        font-size: 1em;
      }
      .email{
        margin-bottom: 0px;
        font-size: 0.7em;
      }
      .phone{
        font-weight: 0;
        font-size: 0.7em;
      }
    }
  }

  @media screen and (max-width: 1024px)and (min-width: 769px){
    .left{
      img{
        width: 45px;
      }
      .social{
        margin-top: 1vh;
        img{
          width: 25px;
         
        }
      }
    }
    .center{
      .link-set{
        a{
          padding: 4px 10px;
          font-size: 1em;
        }
      }
    }
    .right{
      .title{
        margin-bottom: 5px;
        font-size: 1em;
      }
      .email{
        margin-bottom: 0px;
        font-size: 1em;
      }
      .phone{
        font-weight: 0;
        font-size: 1em;
      }
    }
  }

  @media screen and (max-width: 2000px)and (min-width: 1025px){
    .container-fluid{
      margin:500px 600px !important;
    }

    .left{
      img{
        width: 45px;
      }
      .social{
        margin-top: 1vh;
        img{
          width: 30px;
         
        }
      }
    }
    .center{
      .link-set{
        a{
          padding: 4px 20px;
          font-size: 1em;
        }
      }
    }
    .right{
      .title{
        margin-bottom: 5px;
        font-size: 1em;
      }
      .email{
        margin-bottom: 0px;
        font-size: 1em;
      }
      .phone{
        font-weight: 0;
        font-size: 1em;
      }
    }
  }
`;

class Footer extends Component {
  render() {
    return (
      <Section className="container-fluid" id="footer-contact">
        <Row>
          <Col sm="3" className="left">
            <div className="flex-wrap">
              <div>
                <img
                  alt=""
                  src="images/meetrix_logo.png"
                  className="onboard"
                />
              </div>
              <div className="social">
                <a href="https://www.facebook.com/meetrix/">
                  <Facebook />
                </a>
                <a href="https://www.linkedin.com/company/meetrixsolutions">
                  <LinkedIn />
                </a>
                <a href="https://join.skype.com/invite/gGbl8h49dCaa">
                
                </a>
                {/* <a href="https://github.com/meetrix">
                <i className="fab fa-github-square"></i>
                </a> */}
              </div>
            </div>
          </Col>
          <Col sm="6" className="center">
            <div className="link-set">
              <AnchorLink className="nav-item" href="#landing-page-intro">
                Home
              </AnchorLink>
              <AnchorLink className="nav-item" href="#/">
                Pricing
              </AnchorLink>
              <a href="/blog/guides.html">
                Blog
              </a>
            </div>
          </Col>
          <Col sm="3" className="right">
            <div className="title">
              Contact us
            </div>
            <div className="email">
              hello@meetrix.io
            </div>
            <div className="phone">
              +9411 217 1066
            </div>
          </Col>
        </Row>
      </Section>
    );
  }
}

export default Footer;
