import React, { Component } from 'react';
import MainComponent from './Components/Landing/MainComponent';


class App extends Component {
  render() {
    return (
      <MainComponent />
    );
  }
}


export default App;
